import { useScript } from "./useScript";

export const useAnalitycs = () => {
  useScript({
    src: `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_ID}`,
    async: true,
  });
  useScript({
    content: `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "${process.env.REACT_APP_GTAG_ID}");`,
  });
};
