import { ArrowSvg } from "./arrowSvg";

export const Instructions = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <div>MultiVid</div>
      <div>Instructions</div>
      <h2>Add Videos to the Grid:</h2>
      <span>
        Type or paste a YouTube URL or video ID into the textbox below and press
        "Enter."
      </span>
      <span>The video or stream will be added to the grid.</span>

      <h2>Choose a Video to Watch:</h2>
      <p>
        - Click on a video in the grid to select it. The selected video will
        have a highlighted border.
        <br />
        - Alternatively, press the "Spacebar" to toggle between muting and
        unmuting the selected video.
        <br />- Use number keys (1, 2, etc.) to switch between videos quickly.
      </p>

      <h2>Mute or Unmute:</h2>
      <p>Press the "M" key to mute or unmute all videos in the grid at once.</p>

      <h2>Enjoy Your Videos:</h2>
      <p>Sit back, watch, and enjoy your collection of videos and streams!</p>

      <h2 className="dark">Check Out My Blogs:</h2>
      <a className="dark" href="https://blog.ignaciodegregori.com">
        blog.ignaciodegregori.com
      </a>
      <a className="dark" href="https://how2doit.xyz">
        how2doit.xyz
      </a>
      <a className="dark" href="https://mesamardelplata.com">
        mesamardelplata.com
      </a>
      <ArrowSvg
        style={{
          fill: "white",
          position: "absolute",
          width: "20vw",
          right: 0,
          bottom: 30,
        }}
        className="bounce"
      />
    </div>
  );
};
