import { SVGProps } from "react";

export const ArrowSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 447.641 447.641"
    {...props}
  >
    <path
      d="M253.254,420.02L58.883,225.617h127.578c2.569-19.24,6.3-74.921-28.166-117.694
					C127.838,70.109,75.132,50.942,1.666,50.942L0,33.547c1.252-0.228,31.214-5.926,72.962-5.926
					c88.106,0,236.631,25.955,252.083,197.996h122.595L253.254,420.02z"
    />
  </svg>
);
