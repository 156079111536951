import {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useState,
} from "react";

export type Providers = "YT" | "Twitch";

type Props = {
  onAdd: (id: string, provider: Providers) => void;
  className?: string;
};

export const AddVid = ({ onAdd, className = "" }: Props) => {
  const [val, setVal] = useState("");
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
  }, []);
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter" && val.length) {
        const isTwitch = val.indexOf("twitch") > -1;
        if (isTwitch) {
          onAdd((val || "").split("/").pop() || "", "Twitch");
        } else {
          const regExp =
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
          var match = val.match(regExp);
          if (match && match[6].length === 11) {
            onAdd(match[6], "YT");
          }
        }
        setVal("");
      }
    },
    [onAdd, val]
  );
  return (
    <input
      className={"" + className}
      onChange={handleChange}
      onKeyDown={
        handleKeyDown as unknown as KeyboardEventHandler<HTMLInputElement>
      }
      value={val}
      name="idInput"
      placeholder="url or id"
    />
  );
};
