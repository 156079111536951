import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { AddVid, Providers } from "./components/AddVid";
import { useAnalitycs } from "./hooks/useAnalitycs";
import { LiveVideoPlayer, TwitchPlayer, YoutubePlayer } from "./types/player";
import { useScript } from "./hooks/useScript";
import { Instructions } from "./components/instructions";

// eslint-disable-next-line no-restricted-globals
const url = new URL(location.href);

// get the query string
const receivedQueryString = url.searchParams.get("ids");

// convert the query string to an array
const receivedIds = JSON.parse(receivedQueryString || "[]").map(
  (id: string) => ({
    id: id,
    provider: "YT",
  })
);

function App() {
  const [players, setPlayer] = useState<LiveVideoPlayer[]>([]);
  const [focused, setFocused] = useState<number | null>(null);
  const [isMuted, setMuted] = useState<boolean>(false);
  const [videos, setVideos] =
    useState<{ id: string; provider: Providers }[]>(receivedIds);

  useAnalitycs();
  useScript({
    src: "https://player.twitch.tv/js/embed/v1.js",
  });

  const muteOrUnmute = useCallback(
    (foc: number | null) => {
      if (players) {
        players.forEach((p, i) => {
          if (i === foc) {
            p.unmute();
            window.gtag("event", "unmute", {
              index: i,
            });
          } else {
            p.mute && p.mute();
            window.gtag("event", "mute", {
              index: i,
            });
          }
        });
      }
    },
    [players]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      window.gtag("event", "keyPress", {
        key: e.key,
      });
      switch (e.key) {
        case "Tab":
        case " ":
          let nextFoc: number | null = 0;
          if (focused !== null) {
            if (e.shiftKey) {
              nextFoc = focused === 0 ? null : (focused || 0) - 1;
            } else {
              nextFoc = focused === players.length ? null : (focused || 0) + 1;
            }
          }
          setFocused(nextFoc);
          muteOrUnmute(nextFoc);
          break;
        case "m":
        case "M":
          muteOrUnmute(isMuted ? focused : null);
          setMuted(!isMuted);
          break;
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          let num = Number.parseInt(e.key) - 1;
          muteOrUnmute(focused === num ? null : num);
          setFocused(focused === num ? null : num);
          break;
        default:
          break;
      }
      e.preventDefault();
    },
    [focused, isMuted, muteOrUnmute, players.length]
  );

  useEffect(() => {
    const vids = document.getElementById("vids");
    if (vids) {
      vids.addEventListener("keydown", handleKeyPress);
      return () => vids.removeEventListener("keydown", handleKeyPress);
    }
  }, [handleKeyPress]);

  useEffect(() => {
    let YTScript = document.getElementById("youtubeScript");
    if (!YTScript) {
      let tag = document.createElement("script");
      tag.src = "https://youtube.com/iframe_api";
      tag.id = "youtubeScript";
      let firstScriptTag = document.getElementsByTagName("script")[1];
      if (firstScriptTag && firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    }
  }, []);

  // const onPlayerReady = useCallback(
  //   (event: YT.PlayerEvent) => () => {
  //     window.gtag("event", "player_loaded", {
  //       // videoUrl: event.target.getVideoUrl(),
  //       // videoTitle: event.target.getIframe().title,
  //     });
  //   },
  //   []
  // );

  const resetPlayers = useCallback((vi: typeof videos) => {
    let players = vi.map((v) => {
      switch (v.provider) {
        case "YT":
          return new YoutubePlayer(`video-${v.id}`, v.id) as LiveVideoPlayer;
        case "Twitch":
          return new TwitchPlayer(`video-${v.id}`, v.id) as LiveVideoPlayer;
        default:
          return undefined as unknown as LiveVideoPlayer;
      }
    });
    setPlayer(players);
  }, []);

  window.onYouTubeIframeAPIReady = () => {
    resetPlayers(videos);
  };

  useEffect(() => {
    if (videos.length)
      videos.forEach((v, i, a) => {
        const container = document.getElementById(`overlay-${v.id}`);
        if (container) {
          container.addEventListener("click", (e) => {
            e.preventDefault();
          });
        }
      });
  }, [players, videos]);

  const handleAdd = useCallback(
    (id: string, prov: Providers) => {
      setVideos([...videos, { id, provider: prov }]);
      window.gtag("event", "player_added", {
        videoId: id,
      });
    },
    [videos]
  );

  useEffect(() => {
    resetPlayers(videos);
  }, [resetPlayers, videos]);

  return (
    <div>
      <Instructions className="instructions" />
      <div id={`vids`} className="App">
        {videos.map((v, i) => (
          <div
            className={`videoContainer `}
            id={`container-${v.id}`}
            key={`video-${v.id}`}
            tabIndex={0}
          >
            {i === focused && <div className="vFoc" />}
            <div
              className={`videoOverlay `}
              id={`overlay-${v.id}`}
              tabIndex={1}
            />
            <div id={`video-${v.id}`} tabIndex={0} className={`video`} />
          </div>
        ))}
      </div>
      <AddVid onAdd={handleAdd} className={"addInput"} />
    </div>
  );
}

export default App;
