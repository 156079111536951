export interface LiveVideoPlayer {
    play: () => void
    pause: () => void
    mute: () => void
    unmute: () => void
}
export class TwitchPlayer implements LiveVideoPlayer {
    private player: Twitch.Player | null

    private options = {
        width: 1280,
        height: 720,
        // parent: ['multivid.win'],
        // channel: "<channel ID>",
        muted: true,
        autoplay: true
    } as Twitch.TwitchPlayerContructorOptions

    constructor(divId: string, videoId: string, onReady?: () => {}) {
        if (!!window.Twitch) {
            this.player = null
            // @ts-ignore 
            window.Twitch.ext!.onAuthorized((auth: any) => {
                this.player = new window.Twitch.Player(divId, { video: videoId, ...this.options });
                this.player.addEventListener(Twitch.Player.READY, (e: any) => console.log('video.ready', e))
                this.player.addEventListener(Twitch.Player.VIDEO_READY, (e: any) => console.log('ready', e))
                this.player.addEventListener(Twitch.Player.ERROR, (e: any) => console.log('err', e))
                this.player.play()
            });
        } else {
            this.player = null
        }
    }
    pause() {
        this.player?.pause()
    }
    mute() {
        this.player?.setMuted(true)
    }
    unmute() {
        this.player?.setMuted(false)
    }
    play() {
        this.player?.play()
    }
}

export class YoutubePlayer implements LiveVideoPlayer {
    private player: YT.Player | null

    private options = {
        playerVars: {
            autoplay: 1,
            controls: 0,
            rel: 0,
            mute: 1,
            fs: 0,
            showinfo: 0,
            modestbranding: 1,
        },
        // eslint-disable-next-line no-restricted-globals
        // host: location.host,
    }

    constructor(divId: string, videoId: string, onReady?: () => {}) {
        if (!!window.YT) {
            this.player = new window.YT.Player(divId, { ...this.options, videoId: videoId, events: { onReady: onReady } });
        } else {
            this.player = null
        }
    }
    pause() {
        this.player?.pauseVideo()
    }
    mute() {
        this.player?.mute()
    }
    unmute() {
        this.player?.unMute()
    }
    play() {
        this.player?.playVideo()
    }
}
